.section-hero {
    width: 100%;
    margin-bottom: 0;
    padding-top: 120px;
    padding-bottom: 60px;
    background-image: url("/images/hero-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;

    .container {
        padding: 0 5%;

        h1 {
            font-size: 32px;
            line-height: 1.2;
            font-weight: 700;
            font-family: $font-family-base;
            margin-bottom: 30px;
            margin-top: 50px;
            color: $brand-heading-dark;
            width: 80%;
        }

        p {
            font-size: 17px;
            line-height: 1.3;
            font-weight: 400;
            font-family: $font-family-base;
            margin-top: 40px;
            margin-bottom: 30px;
            width: 100%;
        }

        .logo {
            img {
                height: 40px;
                width: auto;
            }
        }

        .break {
            word-break: break-word;
            hyphens: auto;
        }
    }
}

.hero-box {
    width: 100%;
    background-color: $hero-box-bg;
    padding: 30px 0px;

    .container {

        align-items: flex-start;

        img {
            width: 30%;
            object-fit: contain;
            margin-top: 15px;
        }

        p {
            color: $hero-box-font;
            margin-top: 15px;
            margin-left: 15px;
            line-height: 1.3;

            span {
                color: $hero-box-title-font;
            }
        }

        .desktop {
            display: none;
        }
    }
}

.line {
    width: 100%;
}

@media only screen and (min-width : 450px) {
    .section-hero {
        .container {
            p {
                margin-top: 120px;
                width: 80%;
            }
        }
    }

    .hero-box {
        .container {
            align-items: center;

            img {
                margin-top: 0;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .section-hero {
        background-image: url("/images/hero.png");
        padding-top: 120px;
        background-size: 800px;
        background-position: center;
        min-height: 830px;
        padding-bottom: 30px;

        .container {
            padding: 0;

            h1 {
                margin-top: 80px;
                font-size: 40px;
            }

            p {
                width: 60%;
                margin-top: 60px;
                margin-bottom: 60px;
            }

            .break {
                word-break: normal;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .section-hero {
        min-height: 970px;
        background-size: 1000px;

        .container {
            h1 {
                margin-top: 90px;
            }

            p {
                width: 55%;
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }
    }

    .hero-box {
        background-color: $hero-box-bg;

        .container {
            img {
                width: 200px;
            }

            .desktop {
                display: block;
            }

            .mobile {
                display: none;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .section-hero {
        min-height: 870px;
        background-position: 60% -75%;
        background-size: unset;

        .container {

            h1 {
                font-size: 50px;
                line-height: 1.1;
                width: 60%;
                margin-top: 50px;
            }

            p {
                font-size: 20px;
                width: 50%;
            }
        }
    }

    .hero-box {
        background-color: rgba(255, 255, 255, 0);
        padding: 0;

        .container {
            background-color: $hero-box-bg;
            padding: 40px;

            img {
                border-radius: 50%;
                width: 200px;
                object-fit: cover;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .section-hero {
        min-height: 1000px;
        background-position: top;
        background-size: 1700px;

        .container {
            h1 {
                font-size: 65px;
                line-height: 71px;
            }

            p {
                font-size: 20px;
            }

            h1, p {
                margin-top: 60px;
            }

            .logo {
                img {
                    height: 60px;
                    width: auto;
                }
            }
        }
    }
}
