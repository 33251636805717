.timebeam {
    .container {
        padding: 0px 5% 70px 5%;

        h3 {
            font-size: 20px;
            font-weight: 700;
            line-height: 1.2;
            margin-bottom: 40px;
            color: $brand-heading-dark;
        }

        .row {

            img {
                margin: auto;
                &.desktop {
                    width: 864px;
                }
                &.mobile {
                    width: 343px;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .timebeam {
        .container {
            padding: 0px 0 70px 0;

            h3 {
                font-size: 32px;
                font-weight: 700;
                line-height: 46px;
            }
        }
    }
}