.image-text {
    background-color: $info-bg;
    font-family: $font-family-base;
    color: $brand-copy;

    .container {
        padding: 0px 10px 50px 10px;

        img {
            width: 100%;
            border-radius: 0px;
            display: block;
            margin: auto;
        }

        a {
            color: $brand-link;
            font-weight: 700;
            text-decoration: underline;
        }

        .desktop-image {
           display: none;
        }

        .content {

            h3 {
                font-size: 32px;
                font-weight: 700;
                line-height: 1.2;
                margin-bottom: 30px;
                margin-top: 60px;
                color: $brand-heading-dark;
            }

            p {
                font-size: 17px;
                line-height: 1.4;
                font-weight: 400;
                margin-bottom: 30px;
            }

            span {
                color: $brand-name;
                font-weight: 600;
            }

            .mobile-image {
                padding-bottom: 30px;
            }
        }

        .line {
            margin-top: 60px;
        }
    }
}

.project-lipodia,
.project-ceprica {
    .image-text {
        .container {
            padding: 0px 5% 70px 5%;
        }
    }
}

@media only screen and (min-width : 370px) {
    .image-text {
        .container {
            padding: 0px 10% 70px 10%;
        }
    }
}

@include media-breakpoint-up(sm) {
    .image-text {
        .container {
            padding: 0 0 50px 0;
        }
    }

    .project-ceprica,
    .project-lipodia {
        .image-text {
            .container {
                padding: 0 0 50px 0;
            }
        }
    }
}


@include media-breakpoint-up (md) {

    .image-text {
        .container {
            padding-bottom: 80px;
            padding-top: 60px;

            .desktop-image {
                display: block;
            }

            .content {
                padding-left: 40px;

                h3 {
                    font-weight: 700;
                    margin-top: 0;
                }

                .mobile-image {
                    display: none;
                }
            }
        }
    }

    .project-ceprica,
    .project-lipodia {
        .image-text {
            .container {
                padding-bottom: 80px;
                padding-top: 60px;
            }
        }
    }
}
