.form-anchor {
    position: relative;
    top: -100px;
}

.faq-contact {
    background-color: $faq-contact-bg;
    font-family: $font-family-base;
    color: $brand-copy;

    .container {
        padding: 0 10px 70px 10px;

        h3 {
            font-size: 20px;
            font-weight: 700;
            line-height: 1.2;
            margin-bottom: 40px;
            color: $brand-heading-dark;
        }

        .contact-content.contact-bg {
            background-color: #EAB454;
            color: #251646;

            hr {
                border-bottom: 1px solid #D38933;
            }
        }

        .contact-content {
            background-color: $brand-app;
            color: #fff;
            font-size: 18px;
            padding: 40px;
            margin-bottom: 50px;

            hr {
                width: 100%;
                border: 0;
                height: 0;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                margin: 50px 0;
            }

            span {
                font-weight: 600;
                font-size: 18px;
                width: 100%;
            }

            a {
                text-decoration: underline;
                color: #fff;
            }

            .margin-bottom {
                margin-bottom: 50px;
            }
        }
    }
}

.project-ceprica .faq-contact {
    .container {
        padding: 0px 5% 70px 5%;
    }
}

.project-lipodia .faq-contact {
    .bob-style:hover {
        &:disabled,&.disabled {
            color: $gray-900;
        }
    }
}

@media only screen and (min-width : 370px) {

    .faq-contact {
        .container {
            padding: 0px 10% 70px 10%;

            .contact-content {
                padding: 40px 30px;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .faq-contact {
        .container {
            padding: 0px 0 70px 0;

            h3 {
                font-size: 32px;
                font-weight: 700;
                line-height: 46px;
            }

            .contact-content {
                span {
                    font-size: 20px;
                }
            }
        }
    }

    .project-ceprica .faq-contact {
        .container {
            padding: 0px 0 70px 0;
        }
    }
}

@include media-breakpoint-up (md) {
    .faq-contact {
        .container {
            padding: 0px 0 100px 0;

            .contact-content {
                padding: 40px;

                span {
                    font-size: 18px;
                }
            }
        }
    }

    .project-ceprica .faq-contact {
        .container {
            padding: 0px 0 100px 0;
        }
    }
}

@include media-breakpoint-up (lg) {
    .faq-contact {
        .container {
            .contact-content {
                padding: 40px 30px;
                margin-bottom: 0;
            }

            .faq-content {
                padding-right: 50px;
            }
        }
    }
}
